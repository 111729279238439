<template>
  <v-dialog v-model="showingOptionsModal" fullscreen hide-overlay>
    <v-card>
      <v-toolbar class="elevation-0">
        <v-toolbar-title class="text-h5 grey lighten-2 ">{{ $t('Opciones') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close()" aria-label="close-modal">
          <v-icon>{{icons.mdiClose}}</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="mb-10"></v-divider>
        <v-data-table
          :headers="tableColumns"
          :items="items"
          :loading="loading"
          :hide-default-footer="true"
        >

          <template #[`item.name`]="{ item }">
            <div>{{ optionsParser[item.name] }}</div>
          </template>

          <template #[`item.edit`]="{ item }">

            <v-btn 
              class="me-2 icon-with-label"
              color="alt-primary"
              text
              @click="openOptionScoreForm(item)"
            >
              <div class="d-flex align-center">
                <span class="label">
                  {{$t('Editar')}}
                </span>
                <v-icon size="1.5rem">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </div>
            </v-btn>

          </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="tertiary"
          outlined
          @click="close()"
        >
          {{ $t('Cerrar') }}
        </v-btn>
      </v-card-actions>


      <v-dialog v-model="showingEditOptionModal" width="600">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ 'Cambiar Puntaje' }}
          </v-card-title>
          <v-card-text style="max-height: 500px">
            <v-row class="mt-2 justify-center">
              <v-col cols="12" class="mt-2">
                <v-text-field
                  ref="option_score"
                  v-model="option_score"
                  outlined
                  type="number"
                  dense
                  autocomplete="off"
                  hide-details="auto"
                  :rules="[() => !!option_score || $t('Campo requerido')]"
                  :label="$t('Puntaje')"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
              color="tertiary" 
              outlined 
              @click="() => showingEditOptionModal = !showingEditOptionModal"
            >
              {{$t('Cancelar')}}
            </v-btn>
            <v-btn color="primary" @click="editOptionScore()">
              {{ $t('Editar') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import {
mdiPencilOutline,
mdiClose
} from '@mdi/js'

export default {
  data: () => {
    return {
      showingOptionsModal: false,
      showingEditOptionModal: false,
      items: [],
      loading: false,
      option_score: null,
      option_score_to_edit: {}
    }
  },
  methods: {
    async open() {
      try {
        this.getOptionswithScores()
        this.showingOptionsModal = true
      } catch(e) {
        console.log(e)
      }
    },
    async getOptionswithScores() {
      const response = await this.$api.getOptionswithScores()
      this.items = response.results
    },
    close() {
      this.items = []
      this.showingOptionsModal = false
    },
    openOptionScoreForm(item) {
      this.item_score = item.score ? item.score : null
      this.option_score_to_edit = item
      this.showingEditOptionModal = true
    },
    async editOptionScore() {
      try {
        let form = {
          id: this.option_score_to_edit.id,
          name: this.option_score_to_edit.name,
          score: this.option_score
        }
        const response = await this.$api.editOptionScore(this.option_score_to_edit.id,form)
        this.getOptionswithScores()
        this.$refs.option_score.reset()
        this.showingEditOptionModal = false
      } catch(e) {
        console.log(e)
      }
    }
  },
  computed: {
    tableColumns() {
      return [
        { text: this.$t('Opción'), value: 'name', align: 'start', sortable: false, divider: true,},
        { text: this.$t('Puntaje'), value: 'score', align: 'center', sortable: false, divider: true,},
        { text: this.$t('Editar'), value: 'edit', align: 'center', sortable: false, divider: true,}
      ]
    },
    optionsParser() {
      const parser = {
        "A": this.$t('No aplica'),
        "B": this.$t('No se encuentra evidencia'),
        "C": this.$t('Focal'),
        "D": this.$t('En expansión'),
        "E": this.$t('Transversal'),
        "F": this.$t('Política institucional'),
        "G": this.$t('Cultura innovadora'),
      }
      return parser
    },
  },
  setup() {
    return {
      icons: {
        mdiPencilOutline,
        mdiClose
      }
    }
  }
}
</script>
