<template>
  <div>
  <v-card>
    <v-divider class="mt-4"></v-divider>

    <!-- actions -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-btn
          v-if="getUserRole !== 3"
          color="primary"
          class="mb-4 me-3"
          @click="() => $refs.options_list_modal.open()"
          >
            {{ $t('Opciones') }}
        </v-btn>
      </div>
    </v-card-text>
      <!-- table -->
      <v-data-table
      :no-data-text="getError ? getError : $t('No hay datos disponibles.')"
      :headers="tableColumns"
      :items="instrumentListTable"
      :server-items-length="totalInstrumentListTable"
      :loading="loading"
      :hide-default-footer="true"
      >

      <!-- name -->
      <template #[`item.name`]="{item}">

        <div class="d-flex flex-column ms-3">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ instrumentParser[item.level]}}</span>
        </div>

      </template>

      <!-- action -->
      <template #[`item.actions`]="{ item }">

        <v-btn 
          class="icon-with-label"
          color="alt-primary"
          text
          @click="() => $refs.school_instrument.open(item, true, false)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Editar')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiPencilOutline }}
            </v-icon>
          </div>
        </v-btn>
        <v-btn 
          class="me-2 icon-with-label"
          color="alt-primary"
          text
          @click="() => $refs.school_instrument.open(item, false, false)"
        >
          <div class="d-flex align-center">
            <span class="label">
              {{$t('Ver')}}
            </span>
            <v-icon size="1.5rem">
              {{ icons.mdiEyeOutline }}
            </v-icon>
          </div>
        </v-btn>

      </template>

      </v-data-table>
      <school-instrument ref="school_instrument"/>
      <options-list ref="options_list_modal"/>
  </v-card>
  </div>
</template>

<script>
// const debounce = require('debounce');

// import data from './datatable'
import SchoolInstrument from '@/components/Instruments/SchoolInstrument'
import OptionsList from '@/components/Instruments/OptionsList'
import { ref } from '@vue/composition-api';
import { useUtils } from '@core/libs/i18n'
// import debounce from '../../plugins/debounce.js'

import {
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPencilOutline,
  mdiEyeOutline
} from '@mdi/js'


export default {
  components: {
    SchoolInstrument,
    OptionsList
  },
  data: ()  => {
    return {
      loading: false,
      getError: null,
    }
  },
  methods: {
    async getInstrumentList() {
      this.loading = true
      try {
        const response = await this.$api.getInstrumentList()
        this.instrumentListTable = response.results
        this.totalInstrumentListTable = response.count
      } catch (error) {
        if(error?.response?.status === 403) {
          this.getError = error?.response?.data?.detail
        }
      } finally {
        this.loading = false
      }
    },
  },
  mounted() {
    this.getInstrumentList()
  },
  computed: {
    tableColumns() {
      return [
      { text: this.$t('Nombre'), value: 'name', align: 'start', sortable: false },
      { text: this.$t('Acciones'), value: 'actions', align: 'center', sortable: false }
      ]
    },
    instrumentParser() {
      const parser = {
        0: this.$t('Instrumento Preescolar'),
        1: this.$t('Instrumento Escolar')
      }
      return parser
    },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
  },
  setup() {
    const totalInstrumentListTable = ref(2);
    const instrumentListTable = ref([]);
    const { t } = useUtils()

    return {
      totalInstrumentListTable,
      instrumentListTable,
      t,
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline
      },
    }
  },
}
</script>

<style scoped>
.no-uppercase {
text-transform: unset !important;
}
</style>
