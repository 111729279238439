import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center flex-wrap"},[(_vm.getUserRole !== 3)?_c(VBtn,{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function () { return _vm.$refs.options_list_modal.open(); }}},[_vm._v(" "+_vm._s(_vm.$t('Opciones'))+" ")]):_vm._e()],1)],1),_c(VDataTable,{attrs:{"no-data-text":_vm.getError ? _vm.getError : _vm.$t('No hay datos disponibles.'),"headers":_vm.tableColumns,"items":_vm.instrumentListTable,"server-items-length":_vm.totalInstrumentListTable,"loading":_vm.loading,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(_vm.instrumentParser[item.level]))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{staticClass:"icon-with-label",attrs:{"color":"alt-primary","text":""},on:{"click":function () { return _vm.$refs.school_instrument.open(item, true, false); }}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Editar'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")])],1)]),_c(VBtn,{staticClass:"me-2 icon-with-label",attrs:{"color":"alt-primary","text":""},on:{"click":function () { return _vm.$refs.school_instrument.open(item, false, false); }}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t('Ver'))+" ")]),_c(VIcon,{attrs:{"size":"1.5rem"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)])]}}],null,true)}),_c('school-instrument',{ref:"school_instrument"}),_c('options-list',{ref:"options_list_modal"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }