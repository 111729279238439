<template>
    <div>
      <v-card class="mb-6">
        <v-card-title>{{t('Instrumentos')}}</v-card-title>
        <instrument-list />
      </v-card>
  
    </div>
  </template>
  
  <script>
  import { useUtils } from '@core/libs/i18n'
  import InstrumentList from '@/components/Instruments/InstrumentList'
//   import UserList from '@/components/Users/UserList.vue';


  export default {
   components: {
    InstrumentList,
},
   setup() {
    const { t } = useUtils()
      return {
        t,
        // icons
        icons: {
        },
      }
    },
  
  }
  </script>